<template>
  <v-container fluid>
      <v-banner class="mb-5 white--text" sticky app rounded color="error" :value="userChanged">
          <v-avatar slot="icon" color="error" size="40">
              <v-icon color="white">
                  mdi-alert-decagram
              </v-icon>
          </v-avatar>
          {{ $t('user.updateUserAlertText') }}
          <template v-slot:actions>
              <v-btn :loading="loading" color="white" text @click="updateUserSettings">
                  {{ $t('user.updateUserConfirmButton') }}
              </v-btn>
              <v-btn :loading="loading" color="white" text @click="resetCurrentUser">
                  {{ $t('user.updateUserCancelButton') }}
              </v-btn>
          </template>
      </v-banner>
      <v-card>
        <v-row>
          <v-col cols="12" md="4">
              <settings-menu-component @updateTab="changeTab" />
          </v-col>
          <v-divider :vertical="!$vuetify.breakpoint.xs"></v-divider>
          <v-col cols="12" md="8">
              <settings-tabs :tab="tab" :newUser="newUser" />
          </v-col>
      </v-row>
      </v-card>

  </v-container>
</template>

<script>
import SettingsMenuComponent from '../../components/misc/SettingsMenuComponent.vue'
import SettingsTabs from '../../components/tabs/SettingsTabs.vue'
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex';
const _ = require('lodash');
export default {
  components: { SettingsMenuComponent, SettingsTabs },
  name: 'user-settings-page',
  data() {
      return {
          loading: false,
          newUser: {},
          tab: "account-tab"
      }
  },
  computed: {
      ...mapFields("loggedUser", { user: 'loggedUser' }),
      userChanged() {
          return !_.isEqual(
              _.omit(this.user, ['pricingPlan']),
              _.omit(this.newUser, ['pricingPlan'])
          )
      }
  },
  methods: {
      ...mapActions("loggedUser", ["updateUserData"]),
      ...mapActions("alert", ["createAlert"]),
      changeTab(tab) {
          this.tab = tab
      },
      updateUserSettings() {
          this.loading = true;
          this.updateUserData(this.newUser)
              .then(() => {
                  this.createAlert({ message: this.$t('message.updateUserSuccess'), type: "success" })
              })
              .catch(() => {
                  this.createAlert({ message: this.$t('message.updateUserError'), type: "error" })
              })
              .finally(() => {
                  this.loading = false;
              })
      },
      resetCurrentUser() {
          this.newUser = _.cloneDeep(this.user)
      },
  },
  created() {
      this.resetCurrentUser()
  }
}
</script>

<style></style>