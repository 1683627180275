<template>
    <v-dialog v-model="showDialog" max-width="800px" persistent>
        <v-card :class="getCardClass">
            <v-app-bar flat color="rgba(0, 0, 0, 0)">
                <v-toolbar-title>
                    <v-row :align="'center'" :justify="'center'">
                        <v-col :align="'center'" :justify="'center'">
                            <v-icon :color="getIconColor" class="mb-1">
                                {{ getIcon }}
                            </v-icon>
                            {{ title }}
                        </v-col>
                    </v-row>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="undo()" v-if="!isError">
                    <v-icon color="error">
                        mdi-close-box
                    </v-icon>
                </v-btn>
            </v-app-bar>
            <v-card-text>
                <slot name="body">
                    <span v-if="message">{{ message }}</span>
                </slot>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn v-if="isError" @click="undo()" tile :loading="loading" text>
                        {{ $t("button.cancel") }}</v-btn>
                <slot name="confirm_button">
                    <v-btn :color="getButtonColor" tile class="ma-3" @click="confirm()" :loading="loading" >
                        {{ confirmButtonText ? confirmButtonText : $t("button.confirm") }}</v-btn>
                </slot>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "confirm-dialog",
    props: {
        title: {
            type: String,
            default: null
        },
        message: {
            type: String,
            default: null
        },
        showDialog: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false,
        },
        confirmButtonText: {
            type: String,
            required: false,
            default: null
        },
        isError: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    methods: {
        confirm() {
            this.$emit("confirmed", true)
        },
        undo() {
            this.$emit("confirmed", false)
        }
    },
    computed: {
        getCardClass() {
            if (this.isError) {
                return "error-top-color"
            } else {
                return "info-top-color"
            }
        },
        getIcon() {
            if (this.isError) {
                return "mdi-alert-circle"
            } else {
                return "mdi-information"
            }
        },
        getIconColor() {
            if (this.isError) {
                return "error"
            } else {
                return "accent"
            }
        },
        getButtonColor(){
            if (this.isError) {
                return "error"
            } else {
                return "accent"
            }
        }
    }
}
</script>

<style scoped>
.error-top-color {
    border-top: solid 5px var(--v-error-base) !important;
}

.info-top-color {
    border-top: solid 5px var(--v-accent-base) !important;
}
</style>