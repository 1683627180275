<template>
    <v-switch v-model="toggle" inset @change="updateTheme" persistent-hint :hint="getHint" :label="$t('label.theme')" color="accent"></v-switch>
</template>

<script>

export default {
    name: 'theme-switch',
    props: {
        selectedTheme: {
            type: String,
            required: false,
        }
    },
    data() {
        return {
            toggle: null,
        }
    },
    computed: {
        getThemeFromToggle() {
            if (this.toggle) {
                return "DARK"
            } else {
                return "LIGHT"
            }
        },
        getHint() {
            if (this.toggle) {
                return this.$t('label.darkMode')
            } else {
                return this.$t('label.lightMode')
            }
        }
    },
    watch: {
        selectedTheme(newValue, oldValue) {
            if (newValue != oldValue) {
                this.toggle = this.isDarkMode(newValue)
            }
        }
    },
    methods: {
        isDarkMode(theme) {
            return theme == 'DARK';
        },
        updateTheme() {
            const theme = this.getThemeFromToggle
            this.$emit("updateTheme", theme)
        }
    },
    created() {
        this.toggle = this.isDarkMode(this.selectedTheme)
    }
}
</script>

<style>
</style>