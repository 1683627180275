<template>
  <v-select v-model="country" :items="countries" @change="updateCountry" :label="$t('label.country')" solo persistent-hint :hint="$t('label.country')">
    <template v-slot:item="{ item }">
      <v-avatar size="25">
        <v-img :src="require(`../../assets/country/${item.value}.svg`)"></v-img>
      </v-avatar>
      <span class="mx-1">
        {{ item.value | CountryTypeFormatter}}
      </span>
    </template>
    <template v-slot:selection="{ item }">
      <v-avatar size="25">
        <v-img :src="require(`../../assets/country/${item.value}.svg`)"></v-img>
      </v-avatar>
      <span class="mx-1">
        {{ item.value | CountryTypeFormatter }}
      </span>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'country-select',
  props: {
    selectedCountry: {
      type: String,
      required: false,
    },
    countries: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      country: null,
    }
  },
  computed: {
  },
  watch: {
    selectedCountry(newValue, oldValue) {
      if (newValue != oldValue) {
        this.country = newValue
      }
    }
  },
  methods: {
    updateCountry() {
      this.$emit("updateCountry", this.country)
    },
    disableItem (item) {
      // FIXME: remove when Italy is supported
      console.log(item)
      return true
    }
  },
  created() {
    this.country = this.selectedCountry
  }
}
</script>

<style>
</style>