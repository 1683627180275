<template>
  <v-select v-model="language" :items="languages" @change="updateLanguage" :label="$t('label.language')" solo :hint="$t('label.language')" persistent-hint>
    <template v-slot:item="{ item }">
      <v-avatar size="25">
        <v-img :src="require(`../../assets/language/${item}.svg`)"></v-img>
      </v-avatar>
      <span class="mx-1">
        {{ item | LanguageTypeFormatter }}
      </span>
    </template>
    <template v-slot:selection="{ item }">
      <v-avatar size="25">
        <v-img :src="require(`../../assets/language/${item}.svg`)"></v-img>
      </v-avatar>
      <span class="mx-1">
        {{ item | LanguageTypeFormatter}}
      </span>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'language-select',
  props: {
    selectedLanguage: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      language: null,
      languages: ["IT", "EN"]
    }
  },
  computed: {
  },
  watch: {
    selectedLanguage(newValue, oldValue){
      if (newValue != oldValue){
        this.language = newValue
      }
    }
  },
  methods: {
    updateLanguage(){
      this.$emit("updateLanguage", this.language)
    }
  },
  created(){
    this.language = this.selectedLanguage
  }
}
</script>

<style>
</style>