<template>
  <v-select v-model="currency" :items="currencies" @change="updateCurrency" :label="$t('label.currency')" :disabled="disabled" solo :hint="$t('label.currency')" persistent-hint>
    <template v-slot:item="{ item }">
      <v-avatar size="25">
        <v-img :src="require(`../../assets/currency/${item}.svg`)"></v-img>
      </v-avatar>
      <span class="mx-1">
        {{ item }}
      </span>
    </template>
    <template v-slot:selection="{ item }">
      <v-avatar size="25">
        <v-img :src="require(`../../assets/currency/${item}.svg`)"></v-img>
      </v-avatar>
      <span class="mx-1">
        {{ item }}
      </span>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'currency-select',
  props: {
    selectedCurrency: {
      type: String,
      required: false,
    },
    currencies: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      currency: null,
    }
  },
  computed: {
  },
  watch: {
    selectedCurrency(newValue, oldValue) {
      if (newValue != oldValue) {
        this.currency = newValue
      }
    }
  },
  methods: {
    updateCurrency() {
      this.$emit("updateCurrency", this.currency)
    }
  },
  created() {
    this.currency = this.selectedCurrency
  }
}
</script>

<style>
</style>